import { Nav, Navbar, NavLinkProps } from "react-bootstrap";
import React, { useContext } from "react";
import { AuthContext, logIn, logOut } from "./Auth";
import { Link, useLocation } from "react-router-dom";
import { preferDark } from "../App";
import { currentRound } from "../containers/rounds/Rounds";

const CustomNavLink = ({
  name,
  to,
  children,
}: NavLinkProps & { name: string; to: string }) => (
  <Nav.Item>
    <Nav.Link
      to={to}
      as={Link}
      className={"nav-link"}
      style={{
        ...(`/${useLocation().pathname.trim().split("/")[1]}` === to && {
          color: "grey",
        }),
      }}
    >
      {name ?? children}
    </Nav.Link>
  </Nav.Item>
);

export const CustomNavBar = () => {
  const { currentUser } = useContext(AuthContext);
  const theme = preferDark ? "dark" : "light";
  const navItemColor = preferDark ? "lightgrey" : "black";

  return currentUser ? (
    <Navbar bg={preferDark ? "dark" : "white"} expand="md" variant={theme}>
      <Navbar.Brand as={Link} to="/">
        Amazons Forever
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <CustomNavLink to="/measurements" name={"Measurements"} />
        <CustomNavLink to="/rounds" name={"Rounds"} />
        <CustomNavLink to="/users" name={"Amazons"} />
        <CustomNavLink
          as={Link}
          to={`/rounds/${currentRound}?leaderboard`}
          name={"Leaderboard"}
        />
        <Nav.Item className={"ml-auto"}>
          <Nav.Link
            style={{ color: navItemColor }}
            onClick={async () => await logOut()}
          >
            Logout
          </Nav.Link>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  ) : (
    <Navbar bg={preferDark ? "dark" : "white"} expand="sm" variant={theme}>
      <Navbar.Brand as={Link} to="/">
        Amazons Forever
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Item className={"ml-auto"}>
          <Nav.Link
            style={{ color: navItemColor }}
            onClick={async () => await logIn()}
          >
            Login
          </Nav.Link>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};
