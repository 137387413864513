import { Grid } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { BaseSchema, MeasurementType } from "../../types/interfaces";
import { UserSchema } from "../../types/users";
import { byTimestamp } from "../../utils/utils";
import { api } from "../Api";
import { LoadingSpinner } from "../LoadingSpinner";
import FeedItem from "./FeedItem";
import ScrollButton from "./ScrollButton";

const MEASUREMENTS_COUNT = 200;

export const Feed: React.FC = () => {
  const [measurements, setMeasurements] = useState<BaseSchema[]>([]);
  const [users, setUsers] = useState<UserSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const path = `/feed/recent/${MEASUREMENTS_COUNT}`;

    api
      .get(path)
      .then((resp: AxiosResponse<BaseSchema[]>) => {
        const filteredMeasurements = resp.data.filter(
          ({ timestamp, measurement_type }) =>
            timestamp && measurement_type !== MeasurementType.DAILIES
        );
        setMeasurements(filteredMeasurements.sort(byTimestamp));

        return api.get("/user");
      })
      .then((resp: AxiosResponse<UserSchema[]>) => {
        setUsers(resp.data);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setLoading(false);
        setError(e);
      });
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div style={{ color: "red" }}>{error.message}</div>
      ) : (
        <Grid item>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={2}
          >
            {measurements.map((entry: BaseSchema, index) => {
              const user = users.find((u) => u.user_id === entry.user_id);
              return (
                <FeedItem
                  key={entry.id}
                  entry={entry}
                  user={user}
                  index={index}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
      <ScrollButton />
    </>
  );
};

export default Feed;
