import { currentRound } from "../containers/rounds/Rounds";
import { BaseSchema, MeasurementType } from "../types/interfaces";
import { UserSchema } from "../types/users";

export function maybeMeasurementId(): string | undefined {
  return window.location.href
    .replace("new/", "")
    .replace("new", "")
    .split("/measurements/")[1];
}

export function sortBy<T = string>(field: keyof T, a: T, b: T) {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
}

export const byDate = (a: BaseSchema, b: BaseSchema) => sortBy("date", a, b);
export const byTimestamp = (a: BaseSchema, b: BaseSchema) =>
  sortBy("timestamp", b, a);
export const byDisplayName = (a: UserSchema, b: UserSchema) =>
  sortBy<UserSchema>("display_name", b, a);

export const isMobileWidth = (window: Window) => window.innerWidth < 768;

export const setPreferredMeasurement = (measurement: MeasurementType) =>
  localStorage.setItem("preferredMeasurement", measurement.toString());

export const getPreferredMeasurement = (): MeasurementType =>
  (localStorage.getItem("preferredMeasurement") as MeasurementType) ||
  MeasurementType.WEIGHT;

export const setPreferredRound = (Round: number) =>
  localStorage.setItem("preferredRound", Round.toString());

export const getPreferredRound = (): number =>
  Number(localStorage.getItem("preferredRound")) || currentRound;
