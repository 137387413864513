import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const distance = 200;
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > distance) {
      setVisible(true);
    } else if (scrolled <= distance) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <IconButton
      size={"medium"}
      onClick={scrollToTop}
      style={{
        display: visible ? "inline" : "none",
        position: "fixed",
        left: "47.5%",
        bottom: "20px",
        zIndex: 1,
        cursor: "pointer",
      }}
    >
      <ArrowUpwardIcon
        className={"measurement-box"}
        style={{
          fontSize: 30,
          backgroundColor: "black",
          borderRadius: "99999px",
          border: "1px solid white",
          color: "white",
        }}
      />
    </IconButton>
  );
};

export default ScrollButton;
