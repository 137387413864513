import {
  ActivitySchema,
  DistanceSchema,
  GratitudeSchema,
  WeightSchema,
} from "./schemas";
import { WeightUnit } from "./weight";

export enum MeasurementType {
  WEIGHT = "weight",
  DISTANCE = "distance",
  ACTIVITY = "activity",
  GRATITUDE = "gratitude",
  DAILIES = "dailies",
}
export enum MeasurementTypeUnits {
  weight = "lb",
  distance = "km",
  activity = "minutes",
  gratitude = "",
  dailies = "",
}

// This allows us to easily query a dict for getting the unit type for displaying values
// on the results table
export const measurementTableUnits: { [mt: string]: string } = {};
Object.values(MeasurementType).forEach(
  (mt) => (measurementTableUnits[mt] = MeasurementTypeUnits[mt])
);

// GET: /users/:user
export interface UserPage {
  targetWeight: { weight: number; measurement: WeightUnit };
}

export interface CoreFormState {
  date?: string;
  disableAll?: boolean;
  loading: boolean;
  error?: Error;
  successfulSubmit: boolean;
}

export type BaseSchema<Schema extends GenericSchema = NoProps> = {
  date?: string;
  id: string;
  measurement_type: MeasurementType;
  user_id: string;
} & Schema;

export type GenericSchema =
  | WeightSchema
  | ActivitySchema
  | DistanceSchema
  | GratitudeSchema
  | { round: string } // target weight schema
  | Record<string, any>;

export type NoProps = Record<string, never>;