import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { MouseEventHandler, useState } from "react";

import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { CoreFormState, MeasurementType } from "../../types/interfaces";
import { RulesModal } from "./RulesModal";
import { LinkButton } from "../LinkButton";
import { useParams } from "react-router-dom";
import { GoBackButton } from "../../containers/NotFound";

export interface FormWrapperProps extends CoreFormState {
  disableDate?: boolean;
  handleChange: StandardInputProps["onChange"];
  handleSubmit: MouseEventHandler;
  handleDelete?: () => void;
  resetFields: () => void;
  handleDateChange: StandardInputProps["onChange"];
  measurement_type: MeasurementType;
  rules?: string;
  title?: string;
}

const spacing = 10;

export const FormWrapper: React.FC<FormWrapperProps> = (props) => {
  const maybeId = useParams<{ id: string }>().id;
  const [showRules, setShowRules] = useState<boolean>(false);
  const disabled = props.loading || props.disableAll;

  if (!props.measurement_type) throw Error("No measurement type specified");

  return (
    <Typography component={"div"} align={"center"} style={{ padding: 10 }}>
      <FormControl disabled={disabled} style={{ width: "100%" }}>
        {props.rules && (
          <div style={{ margin: spacing }}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => setShowRules(!showRules)}
            >
              Rules
            </Button>
          </div>
        )}

        {props.rules && showRules && (
          <Modal
            open
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RulesModal
              showRules={showRules}
              setShowRules={setShowRules}
              {...props}
            />
          </Modal>
        )}
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          direction={"column"}
        >
          {props.successfulSubmit && (
            <Grid item>
              <div style={{ color: "green" }}>Successful submit!</div>
            </Grid>
          )}
          {props.error && (
            <Grid item>
              <div style={{ color: "red" }}>
                {"Error: "}
                {props.error.message}
              </div>
            </Grid>
          )}
          {props.loading && (
            <Typography
              style={{ paddingTop: 3 }}
              component={"div"}
              align={"center"}
            >
              <CircularProgress size={30} />
            </Typography>
          )}
          {!props.disableDate && (
            <Grid item>
              <TextField
                label="Date"
                type="date"
                name={"date"}
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                value={props.date || ""}
                error={!props.date}
                onChange={props.handleDateChange}
                disabled={disabled}
              />
            </Grid>
          )}

          <Grid item>{props.children}</Grid>
          {!props.disableAll && (
            <Grid item>
              <Button
                color={"primary"}
                disabled={disabled}
                style={{
                  ...(maybeId && { backgroundColor: "green" }),
                  ...(disabled && { cursor: "not-allowed" }),
                }}
                variant={"contained"}
                onClick={props.handleSubmit}
              >
                {maybeId ? "Update" : "Submit"}
              </Button>
              {maybeId && (
                <LinkButton
                  variant={"contained"}
                  color={"primary"}
                  disabled={disabled}
                  style={{ marginLeft: spacing }}
                  to={`/measurements/new`}
                  onClick={props.resetFields}
                >
                  New
                </LinkButton>
              )}
              {/* We can re-enable this if/when we enable the delete button behaviour*/}
              {/*{maybeId && (*/}
              {/*  <Button*/}
              {/*    variant={"contained"}*/}
              {/*    color={"secondary"}*/}
              {/*    size={"small"}*/}
              {/*    disabled={disabled || true} // TODO: Remove this once handleDelete has been implemented*/}
              {/*    style={{ marginLeft: spacing }}*/}
              {/*    onClick={() => props.handleDelete}*/}
              {/*  >*/}
              {/*    Delete*/}
              {/*  </Button>*/}
              {/*)}*/}
            </Grid>
          )}
          {/* Getting rid of this for now */}
          {/*<LinkButton variant={"text"} to={`/measurements`}>*/}
          {/*  Measurements*/}
          {/*</LinkButton>*/}
          <Grid item>
            <GoBackButton />
          </Grid>
        </Grid>
      </FormControl>
    </Typography>
  );
};

export const StyledTextField: React.FC<TextFieldProps> = (
  props: TextFieldProps
) => <TextField {...props} variant={"outlined"} />;
