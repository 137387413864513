import React from "react";
import { Button, ButtonProps, PropTypes } from "@material-ui/core";
import { Link } from "react-router-dom";

interface LinkButtonProps extends ButtonProps {
  to: string;
  style?: React.CSSProperties;
  underline?: boolean;
  color?: PropTypes.Color;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => (
  <Link
    to={props.disabled ? "#" : props.to}
    style={{
      ...(!props.underline && { textDecoration: "none" }),
      ...(props.disabled && { cursor: "not-allowed" }),
    }}
  >
    <Button
      variant={"outlined"}
      size={"small"}
      // color={props.color ?? "primary"}
      {...props}
    >
      {props.children}
    </Button>
  </Link>
);
