import React from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { MeasurementType } from "../../types/interfaces";
import { setPreferredMeasurement } from "../../utils/utils";

// GET: /rounds/:round
function elementForMeasurementType(
  fn: (m: MeasurementType, i: number) => JSX.Element
): JSX.Element[] {
  return (
    Object.values(MeasurementType)
      //  We can get rid of any measurements we don't want here
      .filter((m) => m !== MeasurementType.GRATITUDE)
      .map((m: MeasurementType, index: number) => fn(m, index))
  );
}

export const MeasurementTabs: React.FC<{
  setMeasurement: (m: MeasurementType) => void;
  measurement: MeasurementType;
  disableOthers?: boolean;
}> = (props) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    props.setMeasurement(newValue as MeasurementType);
  };

  setPreferredMeasurement(props.measurement);
  return (
    <Paper square elevation={5}>
      <Tabs
        centered
        value={props.measurement}
        indicatorColor="primary"
        variant={"fullWidth"}
        // textColor="primary"
        onChange={handleChange}
      >
        {elementForMeasurementType((m: MeasurementType, i: number) => (
          <Tab
            wrapped
            key={i}
            id={m.toString() + i}
            label={m}
            value={m}
            disabled={props.disableOthers && m !== props.measurement}
          />
        ))}
      </Tabs>
      {props.children}
    </Paper>
  );
};
