import { AuthContext } from "./components/Auth";
import { Route, Switch } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { Measurements } from "./containers/measurements";
import { Home } from "./containers/Home";
import { Rounds } from "./containers/rounds/Rounds";
import { Round } from "./containers/rounds/Round";
import { Users } from "./containers/users/Users";
import React, { useContext } from "react";
import { TargetWeightForm } from "./containers/measurements/TargetWeightForm";
import { PrivacyPolicy } from "./containers/PrivacyPolicy";
import { User } from "./containers/users/User";
import { NotFound } from "./containers/NotFound";
import { MeasurementForm } from "./containers/measurements/MeasurementForm";
import Login from "./components/Login";

export const Routes = () => {
  const { currentUser } = useContext(AuthContext);

  return currentUser ? (
    <Switch>
      <Route path="/rounds/:round" component={Round} />
      <Route path="/rounds" component={Rounds} />
      <Route path="/user/:user" component={User} />
      <Route path="/users" component={Users} />
      <Route path={"/measurements/new"} exact component={MeasurementForm} />
      <Route path={"/measurements/:id"} component={MeasurementForm} />
      <Route path={"/measurements/targetweight"} component={TargetWeightForm} />
      <Route path="/measurements" exact component={Measurements} />
      <Route path={"/privacy-policy"} component={PrivacyPolicy} />
      <Route exact path="/" component={Home} />
      <Route component={NotFound} />
    </Switch>
  ) : (
    <Switch>
      <Route path={"/privacy-policy"} component={PrivacyPolicy} />
      <Typography align={"center"} style={{ padding: 10 }}>
        <Login />
        <div style={{ paddingTop: 10 }}>
          <Button variant={"contained"} href={"/privacy-policy"}>
            Privacy Policy
          </Button>
        </div>
      </Typography>
    </Switch>
  );
};
