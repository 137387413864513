import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { StyledTextField } from "../../components/forms/FormWrapper";
import { BaseForm, BaseFormProps } from "../../components/forms/BaseForm";
import { MeasurementType } from "../../types/interfaces";
import { WeightUnit } from "../../types/weight";
import { maybeNumberAboveZero } from "../../utils/conversions";
import { WeightSchema } from "../../types/schemas";

const rules = `The weight 'competition' in Round 8 is an individual competition. 

Starting weight is the average of your weights on October 4, 5 and 6 and final weight is average of your weights on 13, 14 and 15 November. 

It's up to each person to declare whether their goal is to lose or to maintain - and also whether you want to set a target weight. 

There will be a spreadsheet into which those two bits of information can be recorded. 

You can enter your info in kg or lbs and it will be converted automatically.

As usual, there will be recognition for achieving one's goals, for most weight lost in absolute terms, and for most weight lost as a percentage of starting weight. 

I encourage people to weigh themselves daily but I know that's not everyone's cup of tea. Some people - like me - like the discipline of posting a photo of their scale daily, or every few days, but that's also optional. But it makes you accountable!
`;

export class WeightForm extends BaseForm<WeightSchema> {
  constructor(props: BaseFormProps) {
    super(props);
    this.state = {
      id: "",
      loading: false,
      measurement_type: MeasurementType.WEIGHT,
      successfulSubmit: false,
      user_id: "",
      value: 0,
      unit: WeightUnit.LB,
    };
  }

  render() {
    return this.wrapper(
      <div>
        <StyledTextField
          label={"Weight"}
          InputLabelProps={{ shrink: true }}
          onChange={this.handleChange}
          type="number"
          name="value"
          value={maybeNumberAboveZero(this.state.value)}
          style={{ maxWidth: 130 }}
          error={!this.state.value}
          disabled={this.state.disableAll}
        />
        <Select
          name={"unit"}
          value={this.state.unit}
          onChange={this.handleChange}
          variant={"outlined"}
          disabled={this.state.disableAll}
        >
          {Object.values(WeightUnit).map((wu) => (
            <MenuItem key={wu} value={wu}>
              {wu}
            </MenuItem>
          ))}
        </Select>
      </div>,
      { rules }
    );
  }
}