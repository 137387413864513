import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useState } from "react";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      await firebase.auth().signInWithPopup(provider);
      // Handle successful login
    } catch (error) {
      console.log("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          startIcon={<FacebookIcon />}
          style={{ textTransform: "none" }}
        >
          Login with Facebook
        </Button>
      )}
    </>
  );
};

export default Login;
