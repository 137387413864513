import { Link, useHistory } from "react-router-dom";
import { Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import HomeIcon from "@material-ui/icons/Home";

export const GoBackButton = () => {
  const history = useHistory();

  return (
    <Button
      style={{ margin: 5 }}
      onClick={() => history.goBack()}
      variant={"contained"}
      color={"primary"}
    >
      Back
    </Button>
  );
};

export const NotFound = () => {
  return (
    <Typography align={"center"} variant={"h3"}>
      Page Not Found
      <div>
        <GoBackButton />
        <br />
        <Link to={"/"}>
          <IconButton>
            <HomeIcon />
          </IconButton>
        </Link>
      </div>
    </Typography>
  );
};
