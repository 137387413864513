import React from "react";
import { StyledTextField } from "../../components/forms/FormWrapper";
import { BaseForm, BaseFormProps } from "../../components/forms/BaseForm";
import { MeasurementType } from "../../types/interfaces";
import { maybeNumberAboveZero } from "../../utils/conversions";
import { ActivitySchema, ActivityUnit } from "../../types/schemas";
import { capitalize, MenuItem, Select } from "@material-ui/core";

const rules = `The activity competition is a team competition, although you are welcome to track your activity minutes here even if you don't want to be on a team.

The aim is to encourage each of us to do a bit more activity than we would normally do and help our team out! 

I will add here shortly who is on which of the three teams.

Please enter the number of minutes you were active each day and identify which activity you engaged in from the drop-down list. 

If there is an activity that doesn't appear there - and you think that it should - please let me know. 

Please try to post your activity minutes daily, or at least every second day or so, to give an accurate sense of how the teams are doing relative to each other. 

We're counting as usual on everyone being honest in what they count! No conversion factors!`;

export class ActivityForm extends BaseForm<ActivitySchema> {
  constructor(props: BaseFormProps) {
    super(props);
    this.state = {
      id: "",
      loading: false,
      successfulSubmit: false,
      user_id: "",
      measurement_type: MeasurementType.ACTIVITY,
      activity: ActivityUnit.AEROBICS,
      value: 0,
      description: "",
    };
  }

  render() {
    return this.wrapper(
      <div>
        <StyledTextField
          label={"Duration (minutes)"}
          InputLabelProps={{ shrink: true }}
          style={{ minWidth: 80 }}
          onChange={this.handleChange}
          type="number"
          name="value"
          inputProps={{ step: 5 }} // 5 min
          error={!this.state.value || this.state.value <= 0}
          value={maybeNumberAboveZero(this.state.value)}
          disabled={this.state.disableAll}
        />
        <Select
          name={"activity"}
          value={this.state.activity}
          onChange={this.handleChange}
          variant={"outlined"}
        >
          {Object.values(ActivityUnit)
            .sort()
            .map((measurement) => (
              <MenuItem key={measurement} value={measurement}>
                {capitalize(measurement.toLowerCase())}
              </MenuItem>
            ))}
        </Select>
        <div style={{ padding: "10px 10px 0px 10px" }}>
          <StyledTextField
            label={
              this.state.disableAll
                ? ""
                : "(Optional) Enter description details here"
            }
            onChange={this.handleChange}
            type="text"
            multiline
            name="description"
            fullWidth
            inputProps={{ style: { minHeight: "10vh" } }}
            value={this.state.description}
            disabled={this.state.disableAll}
          />
        </div>
      </div>,
      { rules }
    );
  }
}
