import { capitalize, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { StyledTextField } from "../../components/forms/FormWrapper";
import { MeasurementType } from "../../types/interfaces";
import { BaseForm, BaseFormProps } from "../../components/forms/BaseForm";
import { DistanceActivity, DistanceUnit } from "../../types/distance";
import { maybeNumberAboveZero } from "../../utils/conversions";
import { DistanceSchema } from "../../types/schemas";

const rules = `
**Do not convert distances yourself, the website will handle it!**

The distance guide is an individual competition this time round. 

Some of you may therefore not want to track your distance but it's there for people who are keen to do so. 

Four types of activities count towards the distance competition: walking/hiking, running (indoors or out), cycling (indoors or out) and swimming (indoors or out). 

Conversion for cycling = divide your distance by 2.5. For swimming = multiply distance x 2. 
`;

export class DistanceForm extends BaseForm<DistanceSchema> {
  constructor(props: BaseFormProps) {
    super(props);
    this.state = {
      id: "",
      loading: false,
      successfulSubmit: false,
      user_id: "",
      measurement_type: MeasurementType.DISTANCE,
      activity: DistanceActivity.WALKING,
      unit: DistanceUnit.KM,
      value: 0,
    };
  }

  render() {
    return this.wrapper(
      <div>
        <div>
          <StyledTextField
            label={"Distance"}
            onChange={this.handleChange}
            type="number"
            name="value"
            value={maybeNumberAboveZero(this.state.value)}
            style={{ maxWidth: 80 }}
            error={!this.state.value}
            disabled={this.state.disableAll}
            // endAdornment={<InputAdornment position="start">km</InputAdornment>}
          />
          <Select
            name={"unit"}
            value={this.state.unit}
            onChange={this.handleChange}
            variant={"outlined"}
            disabled={this.state.disableAll}
          >
            {Object.values(DistanceUnit).map((measurement) => (
              <MenuItem key={measurement} value={measurement}>
                {measurement}
              </MenuItem>
            ))}
          </Select>
          <FormControl>
            <Select
              name={"activity"}
              value={this.state.activity}
              onChange={this.handleChange}
              variant={"outlined"}
              disabled={this.state.disableAll}
            >
              {Object.values(DistanceActivity).map((distanceType) => (
                <MenuItem key={distanceType} value={distanceType}>
                  {capitalize(distanceType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>,
      { rules }
    );
  }
}
