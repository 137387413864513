import { BaseFormState } from "../components/forms/BaseForm";
import {
  BaseSchema,
  GenericSchema,
  MeasurementType,
} from "../types/interfaces";
import {
  ActivitySchema,
  DistanceSchema,
  GratitudeSchema,
  DailiesSchema,
  WeightSchema,
} from "../types/schemas";

export const isWeightForm = (
  p: BaseFormState<GenericSchema>
): p is BaseFormState<WeightSchema> =>
  p.measurement_type === MeasurementType.WEIGHT;

export const isActivityForm = (
  p: BaseFormState<GenericSchema>
): p is BaseFormState<ActivitySchema> =>
  p.measurement_type === MeasurementType.ACTIVITY;

export const isDistanceForm = (
  p: BaseFormState<GenericSchema>
): p is BaseFormState<DistanceSchema> =>
  p.measurement_type === MeasurementType.DISTANCE;

export const isGratitudeForm = (
  p: BaseFormState<GenericSchema>
): p is BaseFormState<GratitudeSchema> =>
  p.measurement_type === MeasurementType.GRATITUDE;

export const isWeightSchema = (
  p: BaseSchema<GenericSchema>
): p is BaseSchema<WeightSchema> =>
  p.measurement_type === MeasurementType.WEIGHT;

export const isActivitySchema = (
  p: BaseSchema<GenericSchema>
): p is BaseSchema<ActivitySchema> =>
  p.measurement_type === MeasurementType.ACTIVITY;

export const isDistanceSchema = (
  p: BaseSchema<GenericSchema>
): p is BaseSchema<DistanceSchema> =>
  p.measurement_type === MeasurementType.DISTANCE;

export const isGratitudeSchema = (
  p: BaseSchema<GenericSchema>
): p is BaseSchema<GratitudeSchema> =>
  p.measurement_type === MeasurementType.GRATITUDE;

export const isDailiesSchema = (
  p: BaseSchema<GenericSchema>
): p is BaseSchema<DailiesSchema> =>
  p.measurement_type === MeasurementType.DAILIES;