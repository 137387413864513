import { BaseFormState } from "../components/forms/BaseForm";
import { GenericSchema } from "../types/interfaces";
import {
  isActivityForm,
  isDistanceForm,
  isGratitudeForm,
  isWeightForm,
} from "./typeguards";

export function handleValidations(
  state: BaseFormState<GenericSchema>
): string[] {
  const errors = [];
  const maybeDate = state?.date;

  if (!maybeDate) {
    errors.push("Date must be valid");
  }

  const maybeYear = maybeDate?.split("-")[0] as string;

  if (
    maybeYear &&
    (maybeYear.length > 4 ||
      Number(maybeYear) < 2019 ||
      Number(maybeYear) > 2023)
  ) {
    errors.push(
      `Please enter a year between 2019 and 2023 (you entered ${maybeYear})`
    );
  }

  if (isWeightForm(state)) {
    if (!state.value || state.value <= 0) {
      errors.push("Weight must be a number above 0");
    }
  } else if (isDistanceForm(state)) {
    if (!state.value || state.value <= 0) {
      errors.push("Distance must be more than 0");
    }

    if (!state.activity) {
      errors.push("Distance type must be filled in");
    }
  } else if (isActivityForm(state)) {
    if (!state.value || state.value <= 0) {
      errors.push("Duration must be more than 0");
    }
    // Disabled to make description optional
    // if (!state.description) {
    //   errors.push("Please fill in description section");
    // }
  } else if (isGratitudeForm(state)) {
    if (!state.value) {
      errors.push("Cannot have nothing to be grateful for!");
    }
  } else {
    errors.push("Validations missing, please add some!");
  }

  return errors;
}
