import React, { useState } from "react";
import { MeasurementType } from "../../types/interfaces";
import { LinkButton } from "../../components/LinkButton";
import { MeasurementTabs } from "../../components/measurements/MeasurementTabs";
import { Typography } from "@material-ui/core";
import { MeasurementGrid } from "../../components/measurements/MeasurementGrid";
import { getPreferredMeasurement, getPreferredRound } from "../../utils/utils";
import { RoundTabs } from "../../components/measurements/RoundTabs";

export const submitMeasurementButtonStyle: React.CSSProperties = {
  margin: "0px 5px 5px 5px",
};

export const SubmitMeasurementButton: React.FC<{
  measurementType: MeasurementType;
}> = () => (
  <LinkButton
    size={"small"}
    color={"primary"}
    variant={"contained"}
    style={submitMeasurementButtonStyle}
    to={`/measurements/new`}
    // to={`/measurements/${props.measurementType.toLowerCase()}`}
  >
    New
  </LinkButton>
);

export const Measurements: React.FC = () => {
  const [measurement, setMeasurement] = useState<MeasurementType>(
    getPreferredMeasurement()
  );
  const [round, setRound] = useState<number>(getPreferredRound());

  return (
    <MeasurementTabs measurement={measurement} setMeasurement={setMeasurement}>
      <RoundTabs round={round} setRound={setRound}>
        <Typography
          component={"div"}
          align={"center"}
          style={{ marginBottom: 10 }}
        >
          <MeasurementGrid measurementType={measurement} round={round} />
        </Typography>
      </RoundTabs>
    </MeasurementTabs>
  );
};
