import React, { ChangeEvent, Component } from "react";
import { AxiosError } from "axios";
import {
  BaseSchema,
  CoreFormState,
  GenericSchema,
} from "../../types/interfaces";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { maybeMeasurementId } from "../../utils/utils";
import {
  isActivityForm,
  isDistanceForm,
  isGratitudeForm,
  isWeightForm,
} from "../../utils/typeguards";
import moment from "moment";
import { FormWrapper, FormWrapperProps } from "./FormWrapper";
import { handleValidations } from "../../utils/validations";
import Container from "@material-ui/core/Container/Container";
import { api } from "../Api";

export interface BaseFormProps {
  data?: BaseSchema | null; // TODO: type this properly
}
export type BaseFormState<Schema> = BaseSchema<Schema> & CoreFormState;

export type SchemaToSubmit = Omit<
  BaseSchema<GenericSchema>,
  "measurement_type"
> & { measurement_type?: string | undefined };

// https://www.mojotech.com/blog/typescript-generic-react-components/
export class BaseForm<Schema extends GenericSchema> extends Component<
  BaseFormProps,
  BaseFormState<Schema>
> {
  today: string = new Date().toISOString().split("T")[0];
  protected currentUser: firebase.User | null = firebase.auth().currentUser;
  constructor(props: BaseFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /* TODO:
   *   - Make sure it's visible to the user when you're updating someone else's measurement*/
  async componentDidMount() {
    this.setState((ps) => ({ ...ps, loading: true }));
    if (this.props.data) {
      const isFromAnotherUser =
        this.currentUser?.uid !== this.props.data.user_id;
      this.setState((ps) => ({
        ...ps,
        ...this.props.data,
        disableAll: isFromAnotherUser,
        loading: false,
      }));
    } else {
      this.setState((ps) => ({ ...ps, loading: false }));
    }
  }

  public handleDateChange(
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const { value, name } = event.currentTarget;
    if (name) this.setState((ps) => ({ ...ps, date: value as string }));
  }

  public handleChange(event: ChangeEvent<{ name?: string; value: unknown }>) {
    const target =
      event.currentTarget.name && event.currentTarget.value
        ? event.currentTarget
        : event.target;
    const { name, value } = target;
    if (name) {
      this.setState((ps) => ({ ...ps, [name]: value }));
    }
  }

  public async handleSubmit(event: { preventDefault: () => void }) {
    this.setState((ps) => ({
      ...ps,
      loading: true,
      successfulSubmit: false,
      error: undefined,
    }));
    const uid = maybeMeasurementId() || uuidv4();

    const validationErrors = handleValidations(this.state);
    if (validationErrors.length > 0) {
      this.handleValidationFailure(validationErrors);
      return;
    }
    const userId = this.state.user_id || this.currentUser?.uid;
    if (!userId) throw Error;

    const data = this.prepareData({ userId, uid }) as SchemaToSubmit;

    const endpoint = `/measurement/${this.state.measurement_type}`;
    delete data.measurement_type;
    this.post(endpoint, data, event);
  }

  private prepareData({
    userId,
    uid,
  }: {
    userId: string;
    uid: string;
  }): BaseSchema<GenericSchema> {
    const baseValues: BaseSchema<{ timestamp: number }> = {
      date: this.state.date,
      user_id: userId,
      id: uid,
      measurement_type: this.state.measurement_type,
      timestamp: moment().unix(),
      // value: this.state.value,
    };
    let data;
    if (isActivityForm(this.state)) {
      data = {
        ...baseValues,
        value: Number(this.state.value),
        description: this.state.description,
        activity: this.state.activity,
      };
    } else if (isDistanceForm(this.state)) {
      data = {
        ...baseValues,
        value: Number(this.state.value),
        activity: this.state.activity,
        unit: this.state.unit,
      };
    } else if (isWeightForm(this.state)) {
      data = {
        ...baseValues,
        value: Number(this.state.value),
        unit: this.state.unit,
      };
    } else if (isGratitudeForm(this.state)) {
      data = { ...baseValues, value: this.state.value };
    } else {
      throw new Error("Unrecognised data to send to API ");
    }
    return data;
  }

  wrapper(
    children: JSX.Element,
    options?: Partial<FormWrapperProps>
  ): JSX.Element {
    return (
      <FormWrapper
        error={this.state.error}
        disableAll={this.state.disableAll}
        measurement_type={this.state.measurement_type}
        loading={this.state.loading}
        successfulSubmit={this.state.successfulSubmit}
        date={this.state.date}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleDateChange={this.handleChange}
        resetFields={() => {
          this.setState((ps) => ({
            ...ps,
            successfulSubmit: false,
            error: undefined,
            value: "",
            description: "", // for activity desc
            date: "",
          }));
        }}
        {...options}
      >
        <Container maxWidth={"xs"} style={{ padding: "0px 10px 0px 10px" }}>
          {children}
        </Container>
      </FormWrapper>
    );
  }

  protected post(
    endpoint: string,
    data: SchemaToSubmit,
    event: { preventDefault: () => void }
  ) {
    api
      .post(endpoint, data, {
        headers: { "content-type": "application/json" },
      })
      .then((res) => {
        if (res.status === 200) {
          this.handleSuccess(res, event);
        } else {
          const error = `Bad status from API: ${res}`;
          console.error(error);
          this.setState((ps) => ({ ...ps, error, loading: false }));
        }
      })
      .catch((err: AxiosError) => {
        console.error(
          "Failed to post to API",
          err.message,
          "POSTed to",
          err.config.url,
          "with data",
          data
        );

        this.setState((ps) => ({
          ...ps,
          error: err?.response?.data,
          loading: false,
        }));
      });
  }

  protected handleValidationFailure(validationErrors: string[]) {
    alert("Form errors:\n  - " + validationErrors.join("\n  - "));
    this.setState((ps) => ({ ...ps, loading: false }));
  }

  protected handleSuccess(
    res: { statusText: string },
    event: { preventDefault: () => void }
  ) {
    this.setState((ps) => ({
      ...ps,
      loading: false,
      successfulSubmit: true,
      date: "",
      value: 0,
      description: "", // this is only to clear the Activity description
    }));
    event.preventDefault();
  }
}