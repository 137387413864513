import React, { MouseEventHandler, useEffect, useState } from "react";
import { Button, Container, Tab, Tabs, Typography } from "@material-ui/core";
import { MeasurementType } from "../../types/interfaces";
import { ResultsTable } from "../ResultsTable";
import { MeasurementTabs } from "../../components/measurements/MeasurementTabs";
import { getPreferredMeasurement } from "../../utils/utils";
import { RouteComponentProps, useLocation } from "react-router-dom";

export interface RoundDataEntry {
  date: number;
  [person: string]: number; // value, value or description of a person I think?
}

export type RoundDataEntries = RoundDataEntry[];
export enum DisplayType {
  TABLE = "table",
  LEADERBOARD = "leaderboard",
}

const spreadsheets: { [roundNumber: string]: string } = {
  "16": "https://docs.google.com/spreadsheets/d/1XlS_803Sz3Vwyt751ze8utHfqCB_gsYAz0ODzbz9acY",
  "15": "https://docs.google.com/spreadsheets/d/1st_6B19wBrIB0l8SDDPxdtXj-S6jNYeccFU_ibmVf7Q",
  "13": "https://docs.google.com/spreadsheets/d/1EEo7Q11Tlj3QZLPue9d9T2RG4gnjJzAnzsWQyz_iysY",
  "12": "https://docs.google.com/spreadsheets/d/10E6UqldCUhxwCM0K822SWGqEBmDHXr96Szf-OuvnFfA/edit?rm=minimal",
  "11": "https://docs.google.com/spreadsheets/d/e/2PACX-1vSOUmC-yI3cJiYc1fIs0LTGPmQoX5TOnJy18wqZC4uyWtB6qCp7XeYGrAOdU2QvH1A73MBoXyYNWf0i/pubhtml?widget=true&amp;headers=false",
  "10": "https://docs.google.com/spreadsheets/d/1y-EjCfdoc7Arb_BWfUC6ohzLmokiViuRESy7xsVOa3w/edit#gid=1266462853?rm=minimal",
  "9": "https://docs.google.com/spreadsheets/d/1h7qhmrCqDNm_XMV4T896HNVmoISkiDw1NXQO3s4Zf3E/edit#gid=1445311789?rm=minimal",
  "8": "https://docs.google.com/spreadsheets/d/1SWvmhTZ-SJKUnYhkrCFMO1K0GiXCYr5hsEl95nqDtlI/edit#gid=1206014471?rm=minimal",
};

export const MeasurementButton: React.FC<{
  measurement: string;
  handleClick: MouseEventHandler;
  disabled: boolean;
}> = (props) => (
  <Button
    style={{ margin: 5 }}
    variant={"contained"}
    color={"primary"}
    size={"small"}
    disabled={props.disabled}
    value={props.measurement}
    name={props.measurement}
    onClick={props.handleClick}
  >
    {props.measurement}
  </Button>
);

function getRoundNumber(props: RouteComponentProps) {
  const paths = props.location.pathname.split("/");
  return paths[paths.length - 1];
}

// Provides data for all 3 main groups
export const Round: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const roundNumber = getRoundNumber(props);

  // get rid of initial "?" by slicing it
  const search = useLocation().search.slice(1) as DisplayType | undefined;
  const initialDisplayType =
    search && Object.values(DisplayType).includes(search)
      ? search
      : DisplayType.TABLE;

  const [measurement, setMeasurement] = useState<MeasurementType>(
    getPreferredMeasurement()
  );
  const [displayType, setDisplayType] =
    React.useState<DisplayType>(initialDisplayType);

  useEffect(() => getData(), [measurement, displayType]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setDisplayType(newValue);
  };

  function getData() {
    localStorage.setItem("preferredMeasurement", measurement.toString());
  }

  return (
    <div>
      <MeasurementTabs
        measurement={measurement}
        setMeasurement={setMeasurement}
      >
        <Tabs
          centered
          value={displayType}
          indicatorColor="primary"
          variant={"fullWidth"}
          // textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {Object.values(DisplayType).map((dt) => (
            <Tab
              key={dt}
              id={"table-or-leaderboard"}
              // disabled={dt === DisplayType.LEADERBOARD} // temporarily disable leaderboard
              label={dt}
              value={dt}
            />
          ))}
        </Tabs>
        {displayType === DisplayType.TABLE ? (
          <ResultsTable measurement={measurement} />
        ) : (
          <Leaderboard roundNumber={roundNumber} />
        )}
      </MeasurementTabs>
    </div>
  );
};

const Leaderboard = ({ roundNumber }: { roundNumber: string }) => (
  // data.length > 0 && renderLineChart(data)
  <Container>
    <Typography align={"center"} style={{ padding: "10px" }}>
      <Button
        size={"large"}
        variant={"contained"}
        color={"primary"}
        target="_blank"
        className={"nav-link"}
        href={spreadsheets[roundNumber]}
      >
        See Latest Results
      </Button>
      <div style={{ padding: 3 }}>
        {`** The below results might be a few hours behind, please click
      "See Latest Results" above if you want the exact current results! **`}
      </div>
      <iframe className="leaderboard-iframe" src={spreadsheets[roundNumber]} />
    </Typography>
  </Container>
);
