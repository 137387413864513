import React, { useEffect, useState } from "react";
import app, { provider } from "../base";
import firebase from "firebase/app";
import { CircularProgress, Typography } from "@material-ui/core";
import { uploadFbProfileToFirebase } from "../utils/firebase";
import { UserSchema } from "../types/users";
import { api } from "./Api";

export const AuthContext = React.createContext<{
  currentUser: firebase.User | null;
}>({ currentUser: null });

function createOrUpdateUser(user: UserSchema) {
  const userData: UserSchema = {
    user_id: user.user_id,
    display_name: user.display_name,
    profile_picture_url: user.profile_picture_url,
  };

  api
    .post("/user", userData)
    .then(() => console.log("axios post /user success"));
}

export const AuthProvider: React.FC = (props) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user: firebase.User | null) => {
      console.log("Running AuthProvider useEffect", user?.displayName);

      if (user) {
        const token = localStorage.getItem("accessToken");
        const size = "large";
        const fbPicUrl = `https://graph.facebook.com/v11.0/${user.providerData[0]?.uid}/picture?access_token=${token}&type=${size}`;
        const path = `users/${user?.uid}/images/profile.jpg`;
        const storageRef = firebase.storage().ref(path);

        const { uid, displayName } = user;

        if (!uid || !displayName) throw Error("Missing UID or display name");

        storageRef
          .getDownloadURL()
          .then((url) =>
            createOrUpdateUser({
              user_id: uid,
              display_name: displayName,
              profile_picture_url: url,
            })
          )
          .catch(() => {
            console.log(
              "No profile picture in Firebase, will try uploading from Facebook"
            );
            uploadFbProfileToFirebase(fbPicUrl, storageRef).then(() => {
              storageRef
                .getDownloadURL()
                .then((url) => {
                  createOrUpdateUser({
                    user_id: uid,
                    display_name: displayName,
                    profile_picture_url: url,
                  });
                })
                .catch(() => {
                  console.log("Still cannot get user image");
                });
            });
          });
      }
      setCurrentUser(user);
      setPending(false);
    });

    async function getToken() {
      const redirectRes = await firebase.auth().getRedirectResult();
      if (redirectRes.credential) {
        const { accessToken } =
          redirectRes.credential as firebase.auth.OAuthCredential;
        if (typeof accessToken === "string") {
          localStorage.setItem("accessToken", accessToken);
        }
      }
    }

    getToken();
  }, []);

  if (pending) {
    return (
      <Typography component={"div"} align={"center"} style={{ paddingTop: 20 }}>
        <CircularProgress />
      </Typography>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export async function logIn() {
  return await app
    .auth()
    .signInWithRedirect(provider)
    .catch((err) => console.log("Firebase login error", err));
}

export async function logOut() {
  await app.auth().signOut();
}
