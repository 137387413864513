import { MeasurementEntry } from "./MeasurementEntry";
import { Grid } from "@material-ui/core";
import React from "react";
import { BaseSchema } from "../../types/interfaces";
import { byDate } from "../../utils/utils";

export const MobileScroller: React.FC<{ measurements: BaseSchema[] }> = (
  props
) => (
  <Grid container spacing={2} justifyContent={"center"}>
    {props.measurements
      .sort(byDate)
      .reverse()
      .map((measurement, index) => (
        <MeasurementEntry
          key={measurement.id}
          entry={measurement}
          prev={props.measurements[index + 1]}
        />
      ))}
  </Grid>
);
