import React from "react";
import { BaseSchema } from "../../types/interfaces";
import { IconButton } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export const DeleteButton: React.FC<{
  entry: BaseSchema;
  deleteMeasurement: (id: string) => Promise<void>;
}> = (props) => (
  <IconButton style={{ position: "absolute", right: 0, bottom: 0, padding: 3 }}>
    <DeleteForeverIcon
      onClick={() => props.deleteMeasurement(props.entry.id)}
    />
  </IconButton>
);
