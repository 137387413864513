export enum WeightUnit {
  KG = "kg",
  // STONE = "stone",
  LB = "lbs",
}

// POST /measurements/value
export interface WeightEntry {
  weight: number;
  measurement: WeightUnit;
  targetWeight?: {
    weight: number;
    measurements: WeightUnit;
  };
}
