import { Divider, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import moment from "moment";
import React, { ReactElement, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DistanceActivity } from "../../types/distance";
import { BaseSchema, MeasurementType } from "../../types/interfaces";
import {
  isActivitySchema,
  isDailiesSchema,
  isDistanceSchema,
  isGratitudeSchema,
  isWeightSchema,
} from "../../utils/typeguards";
import { api } from "../Api";
import { AuthContext } from "../Auth";
import { LoadingSpinner } from "../LoadingSpinner";
import { DeleteButton } from "./DeleteButton";

const commaSeparated = (num: number): string =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const styles: { [name: string]: React.CSSProperties } = {
  entry: {
    height: 100,
    overflow: "scroll",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 5,
    fontSize: "90%",
    flexDirection: "column",
  },
};

function setupEntry(props: { entry: BaseSchema }) {
  let content, maybeIcon;
  const entry = {
    ...props.entry,
    value: Math.round(Number(props.entry.value)),
  };

  if (isActivitySchema(props.entry)) {
    content = (
      <Tooltip title={"description" in props.entry && props.entry.description}>
        <span>
          {commaSeparated(Number(props.entry.value))} minutes{" "}
          {`(${props.entry.activity.toLowerCase()})`}
        </span>
      </Tooltip>
    );
  } else if (isDistanceSchema(props.entry)) {
    content = (
      <div>
        {commaSeparated(props.entry.value)} {props.entry.unit}
      </div>
    );
    maybeIcon =
      props.entry.activity === DistanceActivity.RUNNING ? (
        <DirectionsRunIcon />
      ) : props.entry.activity === DistanceActivity.WALKING ? (
        <DirectionsWalkIcon />
      ) : props.entry.activity === DistanceActivity.CYCLING ? (
        <DirectionsBikeIcon />
      ) : undefined;
  } else if (isWeightSchema(props.entry)) {
    content = (
      <span>
        {commaSeparated(props.entry.value)} {props.entry.unit}
      </span>
    );
  } else if (isGratitudeSchema(props.entry)) {
    content = (
      <Tooltip title={props.entry.value}>
        <div style={{ overflowX: "hidden" }}>
          {props.entry.value.split("\n")[0].substring(0, 30)}
        </div>
      </Tooltip>
    );
  } else if (isDailiesSchema(props.entry)) {
    const value = props.entry.mindful ? "✅" : "❌";
    content = (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  } else {
    throw Error(`Unmatched measurement entry ${JSON.stringify(props.entry)}`);
  }
  return { entry, content, maybeIcon };
}

export const MeasurementEntry: React.FC<{
  entry: BaseSchema;
  prev: BaseSchema | undefined;
}> = (props) => {
  const { user } = useParams<{ user?: string }>();
  const { currentUser } = useContext(AuthContext);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const isCurrentUser = !user || user === currentUser?.uid;

  const entryValues = setupEntry(props);

  async function deleteMeasurement(id: string) {
    setDeleting(true);

    const result = await api.delete(`/measurement/${id}`);
    if (result.status === 200) {
      console.log("deleted ", id);
      setDeleted(true);
    } else {
      console.error("failed to delete ", id, result);
    }
    setDeleting(false);
  }

  const { content, maybeIcon } = entryValues;
  return (
    <Grid
      item
      key={props.entry.date}
      style={{ ...(deleted && { display: "none" }) }}
      xs={5}
      sm={4}
      md={3}
      lg={2}
    >
      <Paper
        elevation={6}
        className={"measurement-box"}
        style={{ ...(deleting && { backgroundColor: "lightgray" }) }}
      >
        {deleting ? (
          <LoadingSpinner />
        ) : (
          isCurrentUser && (
            <DeleteButton
              deleteMeasurement={deleteMeasurement}
              entry={props.entry}
            />
          )
        )}
        <EntryContents
          entry={props.entry}
          content={content}
          maybeIcon={maybeIcon}
          prev={props.prev?.value}
          current={props.entry.value}
          measurementType={props.entry.measurement_type}
        />
      </Paper>
    </Grid>
  );
};

export const EntryContents: React.FC<{
  entry: BaseSchema;
  content: ReactElement;
  maybeIcon?: ReactElement;
  prev?: number;
  current: number;
  measurementType: MeasurementType;
}> = (props): ReactElement => (
  <Link
    style={{ textDecoration: "none", color: "inherit" }}
    to={`/measurements/${props.entry.id}`}
  >
    <Typography style={styles.entry} className={"remove-scrollbar-windows"}>
      <div>
        <Typography
          variant="body1"
          style={{
            ...(props.measurementType === MeasurementType.WEIGHT &&
              props.prev &&
              props.current < props.prev && {
                color: "green",
                fontWeight: "bold",
              }),
          }}
        >
          {props.content}
        </Typography>
        <Typography variant="caption" color="textPrimary">
          {moment(props.entry.date, "YYYY-MM-DD").format("LL")}
        </Typography>
        <Divider />
        <Typography variant="caption" color="textSecondary">
          Submitted {moment.unix(props.entry.timestamp).fromNow()}
        </Typography>
        {props.maybeIcon}
      </div>
    </Typography>
  </Link>
);
