import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  capitalize,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { BaseSchema, MeasurementType } from "../../types/interfaces";
import { UserSchema } from "../../types/users";

interface FeedItemProps {
  entry: BaseSchema;
  user: UserSchema | undefined;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500,
      "&:hover": {
        opacity: 0.6,
        transition: "opacity .25s ease-in-out",
      },
    },
    img: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);

export const FeedItem: React.FC<FeedItemProps> = ({ entry, user }) => {
  const classes = useStyles();
  const submitted = entry?.timestamp;
  const entryDate = entry?.date;

  const EntryDetail = () => (
    <Typography gutterBottom variant="subtitle1">
      <span style={{ fontWeight: "bold" }}>
        {entry.value} {"unit" in entry && `${entry.unit} `}
        {entry.measurement_type === MeasurementType.ACTIVITY && "minutes "}
      </span>
      <span style={{ fontStyle: "italic" }}>
        {"activity" in entry && (entry.activity as string).toLowerCase()}
      </span>
    </Typography>
  );

  return (
    <Grid xs={6} sm={6} md={4} item>
      <Paper elevation={4} square={false} className={classes.paper}>
        <a
          href={`/measurements/${entry.id}`}
          style={{ textDecoration: "inherit", color: "inherit" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item style={{ textAlign: "center" }}>
              <Avatar
                className={classes.img}
                alt="complex"
                src={user?.profile_picture_url}
              />
              <Typography variant="caption" color="textSecondary">
                {user?.display_name.split(" ")[0]}
              </Typography>
            </Grid>
            <Grid
              sm
              item
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              style={{ textAlign: "center" }}
            >
              <Typography variant="caption" color="textSecondary">
                {capitalize(entry.measurement_type)}
              </Typography>
              <EntryDetail />
              <Typography variant="caption">
                {moment(entryDate).format("dddd, MMM Do YYYY")}
              </Typography>
              <Divider />
              <Typography variant="caption" color="textSecondary">
                Submitted {moment.unix(submitted).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </a>
      </Paper>
    </Grid>
  );
};

export default FeedItem;
