import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { measurementTableUnits, MeasurementType } from "../types/interfaces";
import { sortBy } from "../utils/utils";
import moment, { Moment } from "moment";
import firebase from "firebase/app";
import { AuthContext } from "../components/Auth";
import {
  RoundDatumEntry,
  RoundDatumForUser,
  RoundSchema,
} from "../types/rounds";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { api } from "../components/Api";

const sticky: React.CSSProperties = {
  position: "sticky",
  fontWeight: "bold",
  left: 0,
  // backgroundColor: "rgba(255, 255, 255, 0.9)",
  zIndex: 9999,
};

const isToday = (date: Moment) =>
  date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");

export const UserEntryForDate: React.FC<{
  date: string;
  // TODO: Fix this "any"
  user: RoundDatumForUser<any>;
  measurement: MeasurementType;
  currentUser: firebase.User | null;
}> = (props) => {
  //  TODO: Fix this "any"
  const entry: RoundDatumEntry<any> | null = props.user.entries[props.date];
  const userId = props.user.user.user_id;
  const momentDate = moment(props.date, "YYYY-MM-DD");

  return (
    <TableCell
      style={{ ...(isToday(momentDate) && { fontWeight: "bold" }) }}
      align={"right"}
      key={userId || props.date}
    >
      <Tooltip title={momentDate.format("ll")}>
        <div
          style={{
            ...(props.measurement === MeasurementType.ACTIVITY && {
              whiteSpace: "nowrap",
            }),
            maxHeight: 50,
            overflow: "scroll",
            minWidth: 100,
          }}
        >
          {entry ? (
            <div>
              {" "}
              {typeof entry === "boolean" ? (
                entry === true ? (
                  <Check />
                ) : (
                  <Close />
                )
              ) : (
                `${Math.round(Number(entry) * 100) / 100} ${
                  measurementTableUnits[props.measurement]
                }`
              )}
            </div>
          ) : (
            momentDate.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") &&
            "..."
          )}
        </div>
      </Tooltip>
    </TableCell>
  );
};

export const ResultsTable: React.FC<{ measurement: MeasurementType }> = (
  props
) => {
  const { currentUser } = useContext(AuthContext);
  const { round } = useParams<{ round?: string }>();

  const [roundData, setRoundData] = useState<RoundSchema | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const roundUrl = `/rounds/${round}/${props.measurement}/table`;

  useEffect(() => {
    setLoading(true);
    api.get(roundUrl).then((r) => {
      console.log("round data", props.measurement, roundData);
      setRoundData(r.data);
      setLoading(false);
    });
  }, [props.measurement]);

  const startDate = moment(roundData?.start_date, "YYYY-MM-DD");
  // const endDate = moment(roundData?.end_date, "YYYY-MM-DD"); // Show all dates from start to end
  const endDate = moment().format("YYYY-MM-DD"); // Show all dates from start to end

  const dates: string[] = [startDate.clone().format("YYYY-MM-DD")];
  while (startDate.add(1, "days").diff(endDate) < 1) {
    dates.unshift(startDate.clone().format("YYYY-MM-DD"));
  }

  return loading ? (
    <LoadingSpinner />
  ) : roundData ? (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }} variant={"head"}>
              Name
            </TableCell>
            {dates.map((date) => {
              const momentDate = moment(date, "YYYY-MM-DD");
              return (
                <TableCell
                  style={{
                    ...(isToday(momentDate) && {
                      fontWeight: "bold",
                    }),
                    ...(momentDate.format("YYYY-MM-DD") >
                      moment().format("YYYY-MM-DD") && {
                      opacity: 0.8,
                      fontStyle: "italic",
                    }),
                  }}
                  key={date}
                  align="right"
                >
                  <div>
                    {moment(date).format("ll")}
                    <Divider orientation={"vertical"} />
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {roundData?.data
            .sort((a, b) => sortBy("display_name", a.user, b.user))
            .map((userDataWithEntries) => (
              <TableRow key={userDataWithEntries.user.user_id}>
                <TableCell style={sticky} component="th" scope="row">
                  {userDataWithEntries.user.display_name}
                </TableCell>
                {dates.map((date) => (
                  <UserEntryForDate
                    key={date}
                    date={date}
                    currentUser={currentUser}
                    user={userDataWithEntries}
                    measurement={props.measurement}
                  />
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <LoadingSpinner />
  );
};
