import React from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { setPreferredRound } from "../../utils/utils";
import { currentRound } from "../../containers/rounds/Rounds";

export const RoundTabs: React.FC<{
  setRound: (m: number) => void;
  round: number;
  disableOthers?: boolean;
}> = (props) => {
  const handleChange = (_: React.ChangeEvent<{}>, newValue: any) => {
    props.setRound(newValue as number);
  };

  setPreferredRound(props.round);
  return (
    <Paper square>
      <Tabs
        centered
        value={props.round}
        indicatorColor="primary"
        variant={"fullWidth"}
        onChange={handleChange}
      >
        {[...Array(currentRound + 1)]
          .map((n, i) => {
            // Only show the most recent 3 rounds
            if (i < currentRound - 3) {
              return;
            }

            return (
              <Tab
                wrapped
                key={i}
                id={i.toString()}
                label={`round ${i}`}
                value={i}
                disabled={props.disableOthers && i !== props.round}
              />
            );
          })
          .reverse()}
      </Tabs>
      {props.children}
    </Paper>
  );
};
