import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Grid, Paper, Typography } from "@material-ui/core";
import { AuthContext } from "../components/Auth";
import firebase from "firebase/app";
import { Feed } from "../components/home/Feed";
import { ReactNode } from "react-markdown";
import { LinkButton } from "../components/LinkButton";

const QuickLinks = ({ children }: { children: ReactNode }) => (
  <Grid
    container
    justifyContent="center"
    alignContent="space-between"
    alignItems="center"
    spacing={1}
    style={{ paddingTop: 10, paddingBottom: 10 }}
  >
    {children}
  </Grid>
);

const GoTo = (props: { section: string; to?: string }) => (
  <Grid item sm>
    <a href={props.to ?? props.section}>
      <Button variant={"contained"} size={"small"} color={"default"}>
        {props.section}
      </Button>
    </a>
  </Grid>
);
export const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const token = localStorage.getItem("accessToken");
  const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>(
    undefined
  );

  const path = `users/${currentUser?.uid}/images/profile.jpg`;
  const storageRef = firebase.storage().ref(path);

  useEffect(() => {
    storageRef.getDownloadURL().then((url) => setProfilePicUrl(url));
  }, []);

  return (
    <Grid container direction={"column"} spacing={3}>
      <Grid item sm>
        <Paper square elevation={6} style={{ textAlign: "center" }}>
          {token && currentUser?.displayName && (
            <Typography
              component={"div"}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 10,
              }}
            >
              <Avatar
                style={{ height: 150, width: 150 }}
                alt={currentUser.displayName}
                src={profilePicUrl}
              />
            </Typography>
          )}
          <Typography variant={"h5"}>
            Welcome to Amazons Forever,{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentUser?.displayName?.split(" ")[0]}
            </span>
            !
          </Typography>
          <LinkButton
            style={{ margin: 5 }}
            variant={"contained"}
            color="primary"
            size="large"
            to={`/measurements/new`}
          >
            New
          </LinkButton>
          <QuickLinks>
            <GoTo section={"Rounds"} />
            <GoTo section={"Measurements"} />
            <GoTo section={"Leaderboard"} to={"/rounds/10?leaderboard"} />
          </QuickLinks>
        </Paper>
      </Grid>
      <Feed />
    </Grid>
  );
};
