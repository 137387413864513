import React, { useContext, useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import {
  Avatar,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import { BaseSchema, MeasurementType } from "../../types/interfaces";
import { AuthContext } from "../Auth";
import { SubmitMeasurementButton } from "../../containers/measurements";
import { MobileScroller } from "./MobileScroller";
import { byDate } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { UserSchema } from "../../types/users";
import { api } from "../Api";

export const MeasurementGrid: React.FC<{
  measurementType: MeasurementType;
  round: number;
}> = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { user } = useParams<{ user?: string }>();
  const [measurements, setMeasurements] = useState<BaseSchema[]>([]);
  const [userData, setUserData] = useState<UserSchema>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const endpoint = `/user/${user || currentUser?.uid}/measurements/${
      props.measurementType
    }/round/${props.round}`;
    api
      .get(endpoint)
      .then((res: AxiosResponse<[]>) => {
        if (res.status === 200) {
          setMeasurements(res.data);
        } else {
          console.error("failed to get from API", res);
        }
      })
      .then(() =>
        api
          .get(`/user/${user || currentUser?.uid}`)
          .then(({ data }) => setUserData(data))
      )
      .then(() => setLoading(false))
      .catch((err: AxiosError) => {
        setLoading(false);
        setError(err);
      });
  }, [props.measurementType, props.round]);

  const sortedMeasurements = measurements.sort(byDate);

  return (
    <div style={{ padding: 10 }}>
      <Typography
        align={"center"}
        component={"div"}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 5,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          src={userData?.profile_picture_url}
          style={{ width: 100, height: 100 }}
        />
        <div>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            {userData?.display_name}
          </Typography>
        </div>
      </Typography>
      <SubmitMeasurementButton measurementType={props.measurementType} />
      <Divider style={{ marginBottom: "4px" }} />
      {/*  We can re-enable this if/when we implement the target weight tracking*/}
      {/*{props.measurementType === MeasurementType.WEIGHT && (*/}
      {/*  <LinkButton*/}
      {/*    variant={"contained"}*/}
      {/*    color={"default"}*/}
      {/*    disabled*/}
      {/*    style={submitMeasurementButtonStyle}*/}
      {/*    to={`/measurements/target${MeasurementType.WEIGHT}`}*/}
      {/*  >*/}
      {/*    Target*/}
      {/*  </LinkButton>*/}
      {/*)}*/}
      <div style={{ position: "relative" }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <div style={{ color: "red" }}>{error.message}</div>
        ) : (
          <MobileScroller measurements={sortedMeasurements} />
        )}
      </div>
    </div>
  );
};
