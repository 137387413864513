import React from "react";
import { StyledTextField } from "../../components/forms/FormWrapper";
import { BaseForm, BaseFormProps } from "../../components/forms/BaseForm";
import { MeasurementType } from "../../types/interfaces";
import { GratitudeSchema } from "../../types/schemas";

const rules = `Amazons are invited to reflect daily on what they are grateful for in their lives. And then to post on the Amazons Facebook page, one thing that they are grateful for that day.

If you are feeling creative, you could find a photo or an image or whatever that reflects or represents what you are feeling grateful for. 

If you remember, it would also be great if you could post your daily gratitude on the relevant tab within this spreadsheet.`;

export class GratitudeForm extends BaseForm<GratitudeSchema> {
  constructor(props: BaseFormProps) {
    super(props);
    this.state = {
      id: "",
      loading: false,
      successfulSubmit: false,
      user_id: "",
      date: this.today,
      measurement_type: MeasurementType.GRATITUDE,
      value: "",
    };
  }

  render() {
    return this.wrapper(
      <StyledTextField
        label={"Enter description details here"}
        onChange={this.handleChange}
        type="text"
        multiline
        name="value"
        fullWidth
        inputProps={{ style: { minHeight: "10vh" } }}
        value={this.state.value}
        disabled={this.state.disableAll}
      />,
      { rules }
    );
    {
      /* TODO: Implement the image uploader to Firebase Storage*/
    }
    {
      /*<ImageUploader />*/
    }
  }
}
