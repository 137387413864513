import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "firebase/auth";
import "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./components/Auth";
import { BrowserRouter } from "react-router-dom";

if (
  !process.env.REACT_APP_API_BASE_URL &&
  process.env.NODE_ENV === "development"
) {
  console.log(
    "******* You can set an API base url for local testing by setting REACT_APP_API_BASE_URL *******"
  );
  console.log(
    "******* e.g. REACT_APP_API_BASE_URL=http://localhost:1234 ./script/start *******"
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();