import firebase from "firebase/app";
import { api } from "../components/Api";

export function uploadFbProfileToFirebase(
  fbPicUrl: string,
  storageRef: firebase.storage.Reference
) {
  return api
    .get(fbPicUrl, { responseType: "arraybuffer" })
    .then((response) => {
      const b = Buffer.from(response.data, "binary").toString("base64");
      storageRef
        .putString(b, "base64", { contentType: "image/jpeg" })
        .then(() =>
          console.log(
            "Successfully uploaded profile picture to Firebase storage"
          )
        )
        .catch((err) =>
          console.log(
            "Error uploading profile picture to Firebase storage:",
            err
          )
        );
    })
    .catch((e) =>
      console.error(
        "Error getting picture from Facebook via axios",
        e,
        fbPicUrl
      )
    );
}