import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { StyledTextField } from "../../components/forms/FormWrapper";
import { BaseForm, BaseFormProps } from "../../components/forms/BaseForm";
import { MeasurementType } from "../../types/interfaces";
import { WeightUnit } from "../../types/weight";
import { maybeNumberAboveZero } from "../../utils/conversions";
import { maybeMeasurementId } from "../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import axios from "axios";
import { TargetWeightSchema } from "../../types/schemas";
import { handleValidations } from "../../utils/validations";

export class TargetWeightForm extends BaseForm<TargetWeightSchema> {
  constructor(props: BaseFormProps) {
    super(props);
    this.state = {
      round: 0,
      date: this.today,
      id: "",
      loading: false,
      measurement_type: MeasurementType.WEIGHT,
      successfulSubmit: false,
      user_id: "",
      value: 0,
      unit: WeightUnit.KG,
    };
  }

  async handleSubmit(event: { preventDefault: () => void }): Promise<void> {
    this.setState({
      loading: true,
      successfulSubmit: false,
      error: undefined,
    });
    const uid = maybeMeasurementId() || uuidv4();

    const validationErrors = handleValidations(this.state);
    if (validationErrors.length > 0) {
      this.handleValidationFailure(validationErrors);
      return;
    }
    const currentUser = firebase.auth().currentUser;

    if (!currentUser?.uid) {
      throw new Error("No current user UID");
    }

    const data: TargetWeightSchema = {
      user_id: currentUser?.uid,
      id: uid,
      value: Number(this.state.value),
      unit: this.state.unit,
      round: Number(this.state.round),
    };

    // TODO: Build API endpoint to support this
    const endpoint = `/measurement/${MeasurementType.WEIGHT}/target`;
    console.log(" POSTing to", axios.defaults.baseURL + endpoint);
    console.log("Target Weight data to send", data);

    this.post(endpoint, data, event);
    return;
  }

  render() {
    return this.wrapper(
      <div>
        <StyledTextField
          // label={"Round"}
          label={"Round"}
          // InputLabelProps={{ shrink: true }}
          onChange={this.handleChange}
          type="number"
          name="round"
          value={maybeNumberAboveZero(this.state.round)}
          style={{ maxWidth: 100 }}
        />
        <StyledTextField
          label={"Weight"}
          onChange={this.handleChange}
          type="number"
          name="value"
          value={maybeNumberAboveZero(this.state.value)}
          style={{ maxWidth: 100 }}
        />
        <Select
          name={"unit"}
          value={this.state.unit}
          onChange={this.handleChange}
          variant={"outlined"}
        >
          {Object.values(WeightUnit).map((wu) => (
            <MenuItem key={wu} value={wu}>
              {wu}
            </MenuItem>
          ))}
        </Select>
      </div>,
      { disableDate: true }
    );
  }
}
