import React from "react";
import "./App.css";
import { Routes } from "./Routes";
import { CustomNavBar } from "./components/Nav";
import {
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
// export const isLocalEnv = process.env.NODE_ENV === "development";

export const preferDark = window.matchMedia(
  "(prefers-color-scheme: dark)"
).matches;

let theme = createTheme({
  palette: {
    type: preferDark ? "dark" : "light",
  },
});
theme = responsiveFontSizes(theme);

const App = () => (
  <ThemeProvider theme={theme}>
    <CustomNavBar />
    <Container maxWidth={"md"}>
      <Routes />
    </Container>
  </ThemeProvider>
);

export default App;
