import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyDysoUSIYe1PkCiFn_mJynvQtN5QGnKsZ4",
  authDomain: "fir-forever-bc8db.firebaseapp.com",
  projectId: "fir-forever-bc8db",
  storageBucket: "fir-forever-bc8db.appspot.com",
  messagingSenderId: "138228480187",
  appId: "1:138228480187:web:beb5d13ec56351c274519e",
  measurementId: "G-TVZLS7HLPR",
};
const app = firebase.initializeApp(config);
export const provider = new firebase.auth.FacebookAuthProvider();
// provider.addScope("groups_access_member_info");

export default app;
