import { DistanceActivity, DistanceUnit } from "./distance";
import { BaseSchema } from "./interfaces";
import { WeightUnit } from "./weight";

export interface GratitudeSchema {
  value: string;
}

export interface DailiesSchema {
  mindful: boolean;
}

export enum ActivityUnit {
  AEROBICS = "AEROBICS",
  BADMINTON = "BADMINTON",
  BOXING = "BOXING",
  CANOEING = "CANOEING",
  CAROLINE_GIRVAN_WORKOUT = "CAROLINE GIRVAN WORKOUT",
  CLIMBING = "CLIMBING",
  CIRCUITS = "CIRCUITS",
  CROSS_COUNTRY_SKIING = "CROSS COUNTRY SKIING",
  CROSSFIT = "CROSSFIT",
  CROSSTRAINER_ELLIPTICAL = "CROSSTRAINER/ELLIPTICAL",
  CYCLING_INDOORS = "CYCLING (OUT)",
  CYCLING__OUTDOORS = "CYCLING (IN)",
  FOOTBALL = "FOOTBALL",
  GARDENING_RAKING = "GARDENING/RAKING",
  GOLF = "GOLF",
  GYM = "GYM",
  HIIT = "HIIT",
  HIKING = "HIKING",
  HOCKEY_ICE_AND_GRASS = "HOCKEY (ICE AND GRASS)",
  ICE_SKATING = "ICE SKATING",
  JUMPING_ROPE = "JUMPING ROPE",
  KAYAKING = "KAYAKING",
  MARTIAL_ARTS_OF_ALL_KINDS = "MARTIAL ARTS (OF ALL KINDS)",
  // MINDFULNESS = "MINDFULNESS",
  PADDLE_BOARDING = "PADDLE BOARDING",
  PILATES = "PILATES",
  ROWING = "ROWING",
  RUGBY = "RUGBY",
  RUNNING_INDOORS = "RUNNING (OUTDOORS)",
  RUNNING_OUTDOORS = "RUNNING (INDOORS)",
  SHOVELING_SNOW = "SHOVELING SNOW",
  SKIING = "SKIING",
  SKI_ERG = "SKI ERG",
  SNOWBOARDING = "SNOWBOARDING",
  SNOW_SHOEING = "SNOW SHOEING",
  SOCCER = "SOCCER",
  SQUASH = "SQUASH",
  STAIR_CLIMBING = "STAIR CLIMBING",
  SURFING = "SURFING",
  SWIMMING = "SWIMMING",
  TABLE_TENNIS = "TABLE TENNIS",
  TENNIS = "TENNIS",
  VOLLEYBALL = "VOLLEYBALL",
  WALKING = "WALKING",
  WATER_POLO = "WATER POLO",
  WEIGHT_LIFTING = "WEIGHT LIFTING",
  WRESTLING = "WRESTLING",
  YOGA = "YOGA",
}

/*
 * POST: /measurements/description
 */
export interface ActivitySchema {
  value: number; // amount of time spent
  activity: ActivityUnit;
  description: string;
}

export interface DistanceSchema {
  activity: DistanceActivity;
  value: number;
  unit: DistanceUnit;
}

export type WeightSchema = {
  unit: WeightUnit;
  value: number; // value
};

export interface TargetWeightSchema
  extends Omit<BaseSchema<WeightSchema>, "date" | "measurement_type"> {
  round: number;
}