import React, { useEffect, useState } from "react";
import { BaseSchema, MeasurementType } from "../../types/interfaces";
import { MeasurementTabs } from "../../components/measurements/MeasurementTabs";
import { getPreferredMeasurement } from "../../utils/utils";
import { WeightForm } from "./WeightForm";
import { GratitudeForm } from "./GratitudeForm";
import { DistanceForm } from "./DistanceForm";
import { ActivityForm } from "./ActivityForm";
import { useParams } from "react-router-dom";
import { NotFound } from "../NotFound";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { api } from "../../components/Api";

export const MeasurementForm: React.FC = () => {
  const [data, setData] = useState<BaseSchema | null>(null);
  const [measurement, setMeasurement] = useState(
    data?.measurement_type || getPreferredMeasurement()
  );
  const [loading, setLoading] = useState(true);
  const maybeId = useParams<{ id: string }>().id;
  const hasId = !!maybeId;

  useEffect(() => {
    api
      .get(`/measurement/${maybeId}`)
      .then(({ data }: { data: BaseSchema | null }) => {
        if (data) {
          setData(data);
          setMeasurement(data?.measurement_type);
        }
        setLoading(false);
      });
  }, [measurement]);

  return loading ? (
    <LoadingSpinner />
  ) : data || !maybeId ? (
    <MeasurementTabs
      disableOthers={hasId && !!data}
      setMeasurement={setMeasurement}
      measurement={measurement}
    >
      {renderMeasurementForm(measurement, data)}
    </MeasurementTabs>
  ) : (
    <NotFound />
  );
};

function renderMeasurementForm(
  measurement: MeasurementType,
  data: BaseSchema | null
) {
  //    TODO: Make this smarter
  switch (measurement) {
    case MeasurementType.ACTIVITY:
      return <ActivityForm data={data} />;
    case MeasurementType.WEIGHT:
      return <WeightForm data={data} />;
    case MeasurementType.DISTANCE:
      return <DistanceForm data={data} />;
    case MeasurementType.GRATITUDE:
      return <GratitudeForm data={data} />;
    default:
      return <div>Please select a measurement</div>;
  }
}