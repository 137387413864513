import React, { useEffect, useState } from "react";
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AxiosResponse } from "axios";
import { UserSchema } from "../../types/users";
import { Link } from "react-router-dom";
import { api } from "../../components/Api";

export const Users = () => {
  const [users, setUsers] = useState<UserSchema[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    api.get("/user").then((response: AxiosResponse<UserSchema[]>) => {
      setUsers(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <Paper square>
      <Typography variant={"h3"} align={"center"}>
        Amazons
      </Typography>
      <Grid container spacing={1} justifyContent={"center"}>
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          users.map((user) => <UserIcon key={user.user_id} user={user} />)
        )}
      </Grid>
    </Paper>
  );
};

const UserIcon: React.FC<{ user: UserSchema }> = ({ user }) => (
  <Grid item>
    <Link to={`/user/${user.user_id}`}>
      <Tooltip title={user.display_name}>
        <IconButton>
          <Avatar
            alt={user.display_name}
            style={{ height: "70px", width: "70px" }}
            src={user.profile_picture_url}
          />
        </IconButton>
      </Tooltip>
    </Link>
  </Grid>
);