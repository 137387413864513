import axios, { AxiosError } from "axios";

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://fir-forever-bc8db.uc.r.appspot.com",
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // console.error(`API error calling ${error.config.url}`);
    throw error;
  }
);
