import {
  Button,
  capitalize,
  ClickAwayListener,
  Container,
  Fade,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormWrapperProps } from "./FormWrapper";
import ReactMarkdown from "react-markdown";

export const RulesModal: React.FC<
  FormWrapperProps & {
    setShowRules: Dispatch<SetStateAction<boolean>>;
    showRules: boolean;
  }
> = (props) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    document.addEventListener(
      "keydown",
      (event) => {
        if (event.key.toLowerCase() === "escape") {
          props.setShowRules(false);
        }
      },
      false
    );
  }, []);
  return (
    <ClickAwayListener onClickAway={() => props.setShowRules(false)}>
      <Fade in={props.showRules}>
        <Container
          maxWidth={"md"}
          style={{ maxHeight: "90%", overflowY: "scroll" }}
        >
          <Paper elevation={9} style={{ padding: 20 }}>
            <Typography variant={"h4"} align={"center"}>
              {capitalize(props.measurement_type)} rules
            </Typography>
            <div style={{ fontSize: isMobile ? "80%" : "90%" }}>
              <ReactMarkdown>{props.rules || "Rules missing"}</ReactMarkdown>
            </div>
            <Button
              variant={"contained"}
              onClick={() => props.setShowRules(!props.showRules)}
            >
              Close
            </Button>
          </Paper>
        </Container>
      </Fade>
    </ClickAwayListener>
  );
};
