export enum DistanceUnit {
  KM = "km",
  MILES = "miles",
}

export enum DistanceActivity {
  RUNNING = "running",
  SWIMMING = "swimming",
  WALKING = "walking",
  CYCLING = "cycling",
  CROSS_COUNTRY_SKIING = "cross-country skiing",
  SNOW_SHOEING = "snow shoeing",
  SKI_ERG = "ski erg",
  ROWING = "rowing"
}

/*
 * POST: /measurements/value
 */
export interface DistanceEntry {
  distance: number;
  measurement: DistanceUnit;
}
