import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";

export const LoadingSpinner = (props: { style?: React.CSSProperties }) => (
  <Typography
    component={"div"}
    align={"center"}
    style={{ paddingTop: 20, ...props.style }}
  >
    <CircularProgress variant={"indeterminate"} />
  </Typography>
);
