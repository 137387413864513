import React from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";

// This is the first round we started tracking in the app
const STARTING_ROUND = 8;
export const currentRound = 16; // bump this every time there's a new round

// TODO: Query API for these
const getRoundsAndSort = (currentRound: number): number[] => {
  const length = currentRound - STARTING_ROUND + 1; // +1 as we include the first round
  return Array.from({ length }, (_, i) => i + STARTING_ROUND).sort((a, b) =>
    a < b ? 1 : -1
  );
};

export const Rounds = () => (
  <Paper square>
    <Typography variant={"h3"} align={"center"}>
      Rounds
    </Typography>
    <Grid spacing={3} container justifyContent={"center"} direction={"row"}>
      {getRoundsAndSort(currentRound).map((round) => (
        <Grid item key={round}>
          <Link to={`rounds/${round}`}>
            <Paper
              elevation={9}
              className={"measurement-box"}
              style={{ padding: 30, minWidth: 30, textAlign: "center" }}
            >
              {round}
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  </Paper>
);
